import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = (theme: any): any => ({
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
    item: {
        display: "block",
        borderLeft: "4px solid " + theme.palette.divider,
        textDecoration: "none"
    },
    active: {
        borderLeft: "4px solid " + theme.palette.primary.main
    },
    withoutIndicator: {
        borderLeft: "4px solid " + theme.palette.divider,
    }
});


interface INavBarListItemProps {
    to: string;
    title: string;
    inset: boolean;

    onClicked(event: any): void;

    classes: any;
}

interface INavBarListItemStates {
}


class NavBarListItem extends Component<INavBarListItemProps, INavBarListItemStates> {

    shouldComponentUpdate(nextProps: INavBarListItemProps, nextState: INavBarListItemStates) {
        if (this.props.title !== nextProps.title) {
            return true;
        } else if (this.props.inset !== nextProps.inset) {
            return true;
        }
        return false;
    }


    render() {
        const {classes} = this.props;

        return (
            <NavLink to={this.props.to} exact={this.props.to.length < 2} onClick={this.props.onClicked}
                     activeClassName={classes.active}
                     className={classes.item}>
                <ListItem button key={this.props.title}
                          className={this.props.inset ? classes.nested : null}>
                    <ListItemText primary={this.props.title}/>
                </ListItem>
            </NavLink>
        );
    }
}

export default withStyles(styles)(NavBarListItem);