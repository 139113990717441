import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme: any): any => ({
    root: {},
    select: {
        fontSize: "2rem"
    }
});

interface INavigationSelectorProps {
    data: {
        value: string;
        caption: string;
    }[];
    currentValue: string;

    onChange(value: string): void;

    classes: any;

    className: string | undefined;
}

interface INavigationSelectorStates {
}

class NavigationSelector extends Component<INavigationSelectorProps, INavigationSelectorStates> {
    static defaultProps = {
        className: undefined
    };

    render() {
        const {classes} = this.props;

        return (
            <FormControl className={this.props.className}>
                <Select
                    className={classes.select}
                    value={this.props.currentValue}
                    onChange={(event: any) => {
                        console.log(event.target);
                        this.props.onChange(event.target.value)
                    }}
                    inputProps={{
                        name: 'Team',
                        id: 'team',
                    }}
                >
                    {
                        this.props.data.map((entry, idx) => {
                            return (
                                <MenuItem key={idx} value={entry.value}>{entry.caption}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
        );
    }
}

export default withStyles(styles)(NavigationSelector);