import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Typography from "@material-ui/core/Typography";
import ApiHelper, {IMatch} from "../../../api/ApiHelper";

const styles = (theme: any): any => ({
    root: {
        flexGrow: 1,
    },
    date: {
        color: "#333"
    },
    title: {
        ...theme.typography.h1,
        fontSize: "1.4rem",
        textTransform: "none"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    subtitle: {
        textTransform: "uppercase"
    },
    icon: {
        display: "inline-block",
        marginRight: 10,
        height: "100%",
        width: "auto",
        position: "relative"
    },
    avatar: {},
    text: {
        padding: 0,
        color: theme.palette.secondary.contrastText
    },
    result: {
        display: 'inline-flex',
        flex: '0 0 auto',
        backgroundColor: '#333',
        marginLeft: 10,
        marginRight: 10,
    },
    resultText: {
        ...theme.typography.h1,
        fontSize: "1.4rem",
        padding: 10
    }
});

const weekday: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

interface IPastMatchesListProps {
    onClick(id: string): void;

    apiHelper: ApiHelper;

    classes: any;
}

interface IPastMatchesListStates {
    width: number;
    height: number;
    gridHeight: number;
    matches: IMatch[];
}


class PastMatchesList extends Component<IPastMatchesListProps, IPastMatchesListStates> {
    state: IPastMatchesListStates = {
        width: 0,
        height: 0,
        gridHeight: 0,
        matches: []
    };

    componentDidMount(): void {
        this.fetchPastMatches();
    }

    fetchPastMatches = () => {
        if (this.props.apiHelper) {
            this.props.apiHelper.getPastMatches().then((matches: IMatch[]) => {
                this.setState({
                    matches: matches.reverse()
                });
            });
        }
    };

    render() {
        const {classes} = this.props;

        const matches = this.state.matches.map(
            (match: IMatch) => {

                const date = new Date();
                date.setTime(match.timestamp);

                const day: string = (date.getDay() + 1) + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

                let minutes: string = date.getMinutes().toString();
                if (minutes.length === 1)
                    minutes = "0" + minutes;
                const time: string = date.getHours() + ":" + minutes + "h";

                const icon1 = match.attendeeA.team && match.attendeeA.team.icon ? (
                    <ListItemIcon className={classes.icon}>
                        <Avatar className={classes.avatar}
                                src={this.props.apiHelper.getAssetsUrl() + "/" + match.attendeeA.team.icon.path}/>
                    </ListItemIcon>
                ) : "";
                const icon2 = match.attendeeB && match.attendeeB.team && match.attendeeB.team.icon ? (
                    <ListItemIcon className={classes.icon}>
                        <Avatar className={classes.avatar}
                                src={this.props.apiHelper.getAssetsUrl() + "/" + match.attendeeB.team.icon.path}/>
                    </ListItemIcon>
                ) : "";
                const id = match._id;

                const teamACaption = match.attendeeA.team ? (match.attendeeA.team.abbreviation ? match.attendeeA.team.abbreviation : match.attendeeA.team.name) : match.attendeeA.platinLabel;
                const teamBCaption = match.attendeeB.team ? (match.attendeeB.team.abbreviation ? match.attendeeB.team.abbreviation : match.attendeeB.team.name) : match.attendeeB.platinLabel;

                return (
                    <ListItem key={id} button onClick={() => {
                        this.props.onClick(match._id)
                    }}>
                        {icon1}
                        {icon2}
                        <ListItemText className={classes.text} inset>
                            <Typography className={classes.title}>
                                            <span
                                                className={classes.uppercase}>{teamACaption}</span> vs.
                                <span
                                    className={classes.uppercase}>{teamBCaption}</span>
                            </Typography>
                            <Typography className={classes.subtitle}>
                                {weekday[date.getDay()]}, {day}, {time}
                            </Typography>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <IconButton aria-label="Play">
                                <PlayArrowIcon/>
                            </IconButton>
                            <div className={classes.result}>
                                <Typography className={classes.resultText}>
                                    {match.penaltyKickA ? match.scoreA + " (" + match.penaltyKickA + ")" : match.scoreA}
                                    &nbsp;:&nbsp;
                                    {match.penaltyKickB ? match.scoreB + " (" + match.penaltyKickB + ")" : match.scoreB}
                                </Typography>
                            </div>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            });

        return (
            <div className={classes.root}>
                <List
                    component="nav"
                    subheader={<ListSubheader component="div">Past games</ListSubheader>}
                    className={classes.root}
                >
                    {matches}
                </List>
            </div>
        );
    }
}

export default withStyles(styles)(PastMatchesList);