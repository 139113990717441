import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import TableSortLabel from '@material-ui/core/TableSortLabel/index';
import Paper from '@material-ui/core/Paper/index';
import Tooltip from '@material-ui/core/Tooltip/index';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    {id: 'position', sort: true, numeric: true, disablePadding: true, label: 'Pos'},
    {id: 'name', sort: false, numeric: false, disablePadding: true, label: 'Team'},
    {id: 'played', sort: true, numeric: true, disablePadding: true, label: 'G.'},
    {id: 'goals', sort: true, numeric: true, disablePadding: true, label: 'Goals'},
    {id: 'goalDifference', sort: true, numeric: true, disablePadding: true, label: '+/-'},
    {id: 'points', sort: true, numeric: true, disablePadding: true, label: 'Pts.'},
];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {order, orderBy} = this.props;

        return (
            <TableHead>
                <TableRow>
                    {rows.map(
                        row => (
                            <TableCell
                                key={row.id}
                                align='center'
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                {
                                    row.sort ? (
                                        <Tooltip
                                            title={"Sort " + row.label}
                                            placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                            enterDelay={300}
                                        >
                                            <TableSortLabel
                                                active={orderBy === row.id}
                                                direction={order}
                                                onClick={this.createSortHandler(row.id)}
                                            >
                                                {row.label}
                                            </TableSortLabel>
                                        </Tooltip>
                                    ) : row.label
                                }
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const styles = theme => ({
    root: {
        width: '100%'
    },
    table: {},
    tableWrapper: {
        overflowX: 'auto',
    },
});

class ResultTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'position',
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    render() {
        const {classes, teams} = this.props;
        const {order, orderBy} = this.state;

        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={teams.length}
                        />
                        <TableBody>
                            {stableSort(teams, getSorting(order, orderBy))
                                .map((n) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={n._id}
                                        >
                                            <TableCell align="center">{n.position}</TableCell>
                                            <TableCell component="th" scope="row" padding="none">
                                                {n.attendee.team.abbreviation ? n.attendee.team.abbreviation : n.attendee.team.name}
                                            </TableCell>
                                            <TableCell align="center">{n.played}</TableCell>
                                            <TableCell align="center">{n.goals}:{n.goalsAgainst}</TableCell>
                                            <TableCell align="center">{n.goalDifference}</TableCell>
                                            <TableCell align="center">{n.points}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        );
    }
}

ResultTable.propTypes = {
    classes: PropTypes.object.isRequired,
    teams: PropTypes.array.isRequired
};

export default withStyles(styles)(ResultTable);