import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';
import ApiHelper from "./api/ApiHelper";

WebFont.load({
    google: {
        families: ['Roboto Condensed:400,700i', 'Material Icons', 'sans-serif']
    },
    typekit: {
        id: 'lmp3hey'
    }
});

document.title = "NIKE Premier Cup 2019";


const apiHelper = new ApiHelper("https://www.root-design.de/2019", "93cba91e783610a355f53a9bd037b8", "https://www.root-design.de");
ReactDOM.render(
    <App apiHelper={apiHelper}/>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
