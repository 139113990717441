import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import ApiHelper, {IText} from "../../api/ApiHelper";
import ImageGrid from "../../components/elements/ImageGrid";
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';
import ShareButton from "../../components/elements/ShareButton";


const styles = (theme: any): any => ({
    card: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    avatar: {
        width: "2.3rem",
        height: "2.3rem"
    },
});


interface ITextContentProps {
    apiHelper: ApiHelper;
    id: string;
    classes: any;
}

interface ITextContentStates {
    content: IText | null;
}

class TextContent extends Component<ITextContentProps, ITextContentStates> {

    constructor(props: ITextContentProps) {
        super(props);
        this.state = {
            content: null
        }
    }

    fetchData = () => {
        this.props.apiHelper.getText(this.props.id).then(
            (text: IText | null) => {
                this.setState({
                    content: text
                });
            }
        );
    };

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate(nextProps: Readonly<ITextContentProps>, nextState: Readonly<ITextContentStates>, nextContext: any): boolean {
        return (this.props.id !== nextProps.id) || (this.state.content !== nextState.content);
    }


    componentDidUpdate(prevProps: Readonly<ITextContentProps>, prevState: Readonly<ITextContentStates>, snapshot?: any): void {
        if (this.props.id !== prevProps.id) {
            this.fetchData();
        }
    }

    render() {

        if (this.state.content) {
            const {classes} = this.props;

            let header, images, text = null;

            if (this.state.content.images) {
                images = (
                    <ImageGrid images={this.state.content.images} imageUrlPrefix={this.props.apiHelper.getAssetsUrl()}/>
                );
            }
            if (this.state.content.title || this.state.content.subtitle) {
                const title: string = this.state.content.title ? this.state.content.title : "";
                const subtitle: string = this.state.content.subtitle ? this.state.content.subtitle : "";
                const avatar = this.state.content.icon ? (<Avatar aria-label="Recipe" className={classes.avatar}
                                                                  src={this.props.apiHelper.getAssetsUrl() + "/" + this.state.content.icon.path}
                                                                  alt={title}>
                </Avatar>) : null;
                header = (
                    <CardHeader
                        avatar={avatar}
                        action={
                            <ShareButton/>
                        }
                        title={title}
                        titleTypographyProps={{variant: 'h6', color: 'textPrimary'}}
                        subheader={subtitle}
                        subheaderTypographyProps={{variant: 'body2', color: 'textPrimary'}}
                    />
                );
            }
            if (this.state.content.text) {
                text = (
                    <Typography component={'div'}>
                        {this.state.content.text ? ReactHtmlParser(this.state.content.text) : null}
                    </Typography>
                );
            }
            return (
                <Card className={classes.card}>
                    {header}
                    {images}
                    <CardContent>
                        {text}
                    </CardContent>
                </Card>
            );
        }
        return null;
    }
}

/*
function TextContent(props) {
    const {classes} = props;


    return (
        <div className={classes.root}>
            <GridList cellHeight={160} className={classes.gridList} cols={3}>
                {tileData.map(tile => (
                    <GridListTile key={tile.img} cols={tile.cols || 1}>
                        <img src={tile.img} alt={tile.title}/>
                    </GridListTile>
                ))}
            </GridList>
            <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={16}>
                    <Grid item xs>
                        <Typography>{props.message}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}*/

export default withStyles(styles)(TextContent);