import React, {Component} from 'react';
import {FacebookShareButton} from "react-share";
import IconButton from "@material-ui/core/IconButton";
import ShareIcon from '@material-ui/icons/Share';

class ShareButton extends Component<any, any> {


    render() {

        return (
            <FacebookShareButton url={window.location.href}>
                <IconButton>
                    <ShareIcon/>
                </IconButton>
            </FacebookShareButton>
        );
    }
}

export default ShareButton;