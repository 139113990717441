import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core";

const styles = (theme: any) => ({
    grow: {
        flexGrow: 1
    },
    navBar: {
        flexGrow: 1,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.backgroundColor
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 12
    },
    logo: {
        padding: 6,
        maxHeight: 43,
    },
    breadcrumb: {
        ...theme.typography.breadcrumb,
        textTransform: "uppercase",
        flexGrow: 1
    },
    brand: {
        ...theme.typography.brand,
        textTransform: 'none'
    }
});

interface INavBarProps {
    onOpenClicked(event: any): void;

    title: string;
    logo: string;
    brand: string;
    classes: any;
    children: any;
}

class NavBar extends Component<INavBarProps, any> {
    render() {
        const {classes} = this.props;

        return (
            <AppBar className={classes.navBar} position="sticky" color="default">
                <Toolbar>
                    <IconButton onClick={this.props.onOpenClicked} className={classes.menuButton} color="inherit"
                                aria-label="Menu">
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant={this.props.title.length > 9 ? "h5" : "h4"} className={classes.breadcrumb}>
                        {this.props.title}
                    </Typography>
                    <img src={this.props.logo} className={classes.logo}
                         alt="NIKE Premier Cup"/>
                    <Typography variant="h6" color="inherit" className={classes.brand}>
                        {this.props.brand}
                    </Typography>
                </Toolbar>
                {this.props.children}
            </AppBar>
        );
    }
}

export default withStyles(styles)(NavBar);