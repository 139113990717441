import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import ApiHelper, {IVideo} from "../../api/ApiHelper";
import MatchView from "../MatchView";
import SwipeableViews from "react-swipeable-views";

const styles = (): any => ({
    root: {
        flexGrow: 1,
        width: "100%",
        height: "100%"
    },
    view: {
        height: "100%"
    }
});


interface ILiveStreamProps {
    apiHelper: ApiHelper;
    matchId: string;
    baseRoute: string;
    classes: any;

    onMatchIdChanged(matchId: string): void;

    onVideoIdChanged(videoId: string): void;
}

interface ILiveStreamStates {
    liveStreams: IVideo[];
    matchId: string;
}

class LiveStream extends Component<ILiveStreamProps, ILiveStreamStates> {
    constructor(props: ILiveStreamProps) {
        super(props);
        this.state = {
            liveStreams: [],
            matchId: props.matchId
        }
    }

    fetchLiveMatches = () => {
        this.props.apiHelper.getLiveVideos().then((videos: IVideo[]) => {
            this.setState({
                liveStreams: videos
            });
        });
    };

    componentDidUpdate(prevProps: Readonly<ILiveStreamProps>, prevState: Readonly<ILiveStreamStates>, snapshot?: any): void {
        if (prevProps.matchId !== this.props.matchId) {
            this.fetchLiveMatches();
        }
    }

    componentDidMount(): void {
        this.fetchLiveMatches();
    }

    onChangeIndex = (index: number) => {
        // Get current videoId
        const id: string = this.state.liveStreams[index]._id;
        this.setState({
            matchId: id
        });
        this.props.onMatchIdChanged(id);
    };

    onVideoIdChanged = (id: string) => {
        this.props.onVideoIdChanged(id);
    };

    render() {
        const {classes} = this.props;

        return (
            <SwipeableViews className={classes.root} onChangeIndex={this.onChangeIndex}>
                {this.state.liveStreams.map((liveStream: IVideo) => {
                    return (
                        <MatchView className={classes.view} key={liveStream._id} apiHelper={this.props.apiHelper}
                                   baseRoute={this.props.baseRoute} currentVideoId={liveStream._id}
                                   matchId={liveStream.match._id} onVideoClicked={this.onVideoIdChanged}/>
                    );
                })}
            </SwipeableViews>
        );
    }
}

export default withStyles(styles)(LiveStream);