import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import MatchList from '../../components/containers/MatchList';
import ApiHelper, {IMatch} from "../../api/ApiHelper";

const styles = (theme: any): any => ({
    root: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 8,
    }
});


interface IScheduleViewProps {
    apiHelper: ApiHelper;
    classes: any;
}

interface IScheduleViewStates {
}

class ScheduleView extends Component<IScheduleViewProps, IScheduleViewStates> {
    render() {
        const {classes} = this.props;

        return (
            <MatchList apiHelper={this.props.apiHelper} intervalInSeconds={5}
                       className={classes.root}/>
        );
    }
}

export default withStyles(styles)(ScheduleView);