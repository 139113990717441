import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import ResultTable from "../../components/containers/ResultTable";
import NavigationSelector from "../../components/elements/NavigationSelector";
import MatchList from '../../components/containers/MatchList';
import ApiHelper, {IGroup, IGroupTable} from "../../api/ApiHelper";

const styles = (theme: any): any => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto'
    },
    matchList: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 8,
    },
    selector: {
        padding: theme.spacing.unit * 4
    }
});


interface IGroupResultsProps {
    apiHelper: ApiHelper;
    groupId: string;
    classes: any;

    onGroupChangeRequested(groupId: string): void;
}

interface IGroupResultsStates {
    groups: IGroup[];
    groupTables: IGroupTable[];
    tournamentId: string | undefined;
}

class GroupResults extends Component<IGroupResultsProps, IGroupResultsStates> {
    state: IGroupResultsStates = {
        groups: [],
        groupTables: [],
        tournamentId: undefined
    };

    handleNavigationChangeRequest = (groupId: string) => {
        this.setState({
            groupTables: []
        });
        this.props.onGroupChangeRequested(groupId);
    };

    fetchGroups = () => {
        this.props.apiHelper.getGroups().then(
            (groups: IGroup[]) => {
                if (groups)
                    this.setState({groups: groups});
                this.getCurrentTournamentId();
            }
        );
    };

    getCurrentTournamentId = () => {
        let tournamentId = null;
        for (let i = 0; i < this.state.groups.length; i++) {
            if (this.state.groups[i]._id === this.props.groupId) {
                tournamentId = this.state.groups[i].tournament._id;
                break;
            }
        }
        if (tournamentId) {
            this.setState({
                tournamentId: tournamentId
            });
        }
    };

    fetchGroupsTable = () => {
        this.props.apiHelper.getGroupsTableByGroupId(this.props.groupId).then(
            (groupTables: IGroupTable[] | null) => {
                if (groupTables)
                    this.setState({
                        groupTables: groupTables
                    });
            }
        );
    };

    componentDidUpdate(prevProps: Readonly<IGroupResultsProps>, prevState: Readonly<IGroupResultsStates>, snapshot?: any): void {
        if (prevProps.groupId !== this.props.groupId) {
            this.getCurrentTournamentId();
            this.fetchGroupsTable();
        }
    }

    componentDidMount() {
        this.fetchGroups();
        this.fetchGroupsTable();
    }

    render() {
        const {classes} = this.props;

        const selector = this.state.groups ? (
            <NavigationSelector className={classes.selector} currentValue={this.props.groupId}
                                onChange={this.handleNavigationChangeRequest}
                                data={this.state.groups.map((group: IGroup) => {
                                    return {
                                        value: group._id,
                                        caption: group.name
                                    };
                                })}/>
        ) : null;

        let groupsTable = null;
        if (this.state.groupTables) {
            groupsTable = (
                <ResultTable teams={this.state.groupTables}/>
            )
        }

        let matchesTable = (
            <MatchList tournamentId={this.state.tournamentId} apiHelper={this.props.apiHelper}
                       className={classes.matchList} intervalInSeconds={5}/>
        );

        return (
            <div className={classes.root}>
                {selector}
                {groupsTable}
                {matchesTable}
            </div>
        );
    }
}

export default withStyles(styles)(GroupResults);