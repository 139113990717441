import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import NavigationSelector from "../../components/elements/NavigationSelector";
import ApiHelper, {ITeam} from "../../api/ApiHelper";
import {withRouter} from 'react-router-dom';
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';
import ShareButton from "../../components/elements/ShareButton";


const styles = (theme: any): any => ({
    card: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    header: {
        flexGrow: 1,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatar: {},
});


interface ITeamsProps {
    id: number;
    apiHelper: ApiHelper;
    classes: any;

    onTeamURLChangeRequested(groupId: string): void;
}

interface ITeamsStates {
    teams: ITeam[];
    currentTeam: ITeam | null;
}


class Teams extends Component<ITeamsProps, ITeamsStates> {
    state: ITeamsStates = {
        teams: [],
        currentTeam: null
    };

    shouldComponentUpdate(nextProps: ITeamsProps, nextState: ITeamsStates, nextContext_: any) {
        if (this.props.id !== nextProps.id)
            return true;
        if (this.state.teams !== nextState.teams)
            return true;
        if (this.state.currentTeam !== nextState.currentTeam)
            return true;
        return false;
    }

    componentDidUpdate(prevProps: Readonly<ITeamsProps>, prevState: Readonly<ITeamsStates>, snapshot?: any): void {
        if (this.props.id !== prevProps.id) {
            this.fetchData();
        }
    }

    fetchData = () => {
        this.props.apiHelper.getTeams().then(
            (teams: ITeam[]): void => {
                this.setState({
                    teams: teams
                })
            }
        ).then(
            () => {
                if (this.props.id) {
                    if (0 < this.state.teams.length && this.props.id <= this.state.teams.length) {
                        this.setState({
                            currentTeam: this.state.teams[this.props.id]
                        });
                    }
                }
            }
        );
    };

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const {classes} = this.props;

        let image = null;
        let text = null;
        let title = "Team";
        let abbreviation = null;
        let imageDescription = null
        let avatar = null;
        if (this.state.currentTeam) {
            console.log(this.state.currentTeam);
            if (this.state.currentTeam.title)
                title = this.state.currentTeam.title;
            abbreviation = this.state.currentTeam.abbreviation;
            if (this.state.currentTeam.image) {
                image = <CardMedia
                    className={classes.media}
                    image={this.props.apiHelper.getAssetsUrl() + "/" + this.state.currentTeam.image.path}
                    title={this.state.currentTeam.name}
                />;
            }
            if (this.state.currentTeam.imageDescription) {
                imageDescription = (
                    <Typography component="p">
                        {ReactHtmlParser(this.state.currentTeam.imageDescription)}
                    </Typography>
                );
            }
            if (this.state.currentTeam.text) {
                text = (
                    <Typography component="p">
                        {ReactHtmlParser(this.state.currentTeam.text)}
                    </Typography>
                );
            }
            if (this.state.currentTeam.icon) {
                avatar = (
                    <Avatar aria-label="Recipe" className={classes.avatar}
                            src={this.props.apiHelper.getAssetsUrl() + "/" + this.state.currentTeam.icon.path}
                            alt={title}>
                    </Avatar>
                );
            }
        }

        const navigation = (
            <NavigationSelector currentValue={'/teams/' + this.props.id}
                                onChange={this.props.onTeamURLChangeRequested}
                                data={this.state.teams.map((value, idx) => {
                                    return {
                                        value: '/teams/' + idx,
                                        caption: value.name,
                                    };
                                })}/>
        );

        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={avatar}
                    action={
                        <ShareButton/>
                    }
                    title={navigation}
                    subheader={abbreviation}
                    className={classes.header}
                />
                <CardContent>
                    {image}
                    {imageDescription}
                    {text}
                </CardContent>
            </Card>
        );
    }
}

// @ts-ignore
export default withRouter(withStyles(styles)(Teams));