import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import Logo from "../../../logo.svg";
import Drawer from "@material-ui/core/Drawer";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from "@material-ui/core/List";
import NavBarListItem from "../NavBarListItem";
import Typography from "@material-ui/core/Typography";
import {NavLink} from "react-router-dom";
import ApiHelper, {IGroup} from "../../../api/ApiHelper";

const styles = (theme: any): any => ({
    drawerOrganisator: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        justifyContent: "space-between"
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
    grow: {
        flexGrow: 1
    },
    bottomNav: {
        display: "flex",
        flexDirection: "row",
        alignItems: "space-around",
        justifyContent: "space-around"
    },
    subheader: {
        position: "relative",
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: 20
    },
    logo: {
        height: "100%",
        maxWidth: "100%",
        margin: 0,
        maxHeight: "10vh"
    },
    headline: {
        ...theme.typography.drawerHeadline
    },
    item: {
        textDecoration: "none",
        display: "block",
    }
});

interface INavBarDrawerProps {
    open: boolean;
    apiHelper: ApiHelper;
    classes: any;

    onLinkClicked(event: any): void;

    onClose(event: any): void;
}

interface INavBarDrawerStates {
    groups: IGroup[];
    resultsOpen: boolean;
    aboutOpen: boolean;
}

class NavBarDrawer extends Component<INavBarDrawerProps, INavBarDrawerStates> {
    state: INavBarDrawerStates = {
        resultsOpen: false,
        aboutOpen: false,
        groups: []
    };


    shouldComponentUpdate(nextProps: INavBarDrawerProps, nextState: INavBarDrawerStates) {
        if (this.state.resultsOpen !== nextState.resultsOpen) {
            return true;
        } else if (this.state.aboutOpen !== nextState.aboutOpen) {
            return true;
        } else if (this.state.groups !== nextState.groups) {
            return true;
        } else if (this.props.open !== nextProps.open) {
            return true;
        }
        return false;
    }

    getLink = (title: string, link: string, isInset: boolean = false) => {
        return (
            <NavBarListItem to={link} title={title} inset={isInset} onClicked={this.props.onLinkClicked}/>
        );
    };

    getLinkWithoutIndicator = (title: string, link: string, className: any) => {
        return (
            <NavLink to={link} title={title} className={className}
                     onClick={this.props.onLinkClicked}>
                <ListItem button key={title}>
                    <ListItemText primary={title}/>
                </ListItem>
            </NavLink>
        );
    };

    componentDidMount() {
        this.props.apiHelper.getGroups().then(
            (groups: IGroup[]): void => {
                this.setState({
                        groups: groups
                    }
                );
            }
        )
    }

    render() {
        const {classes} = this.props;

        let groupLinks = null;
        if (this.state.groups) {
            groupLinks = this.state.groups.map((group: IGroup) => {
                return (
                    <NavBarListItem key={group._id} to={"/results/groups/" + group._id} title={group.name} inset={true}
                                    onClicked={this.props.onLinkClicked}/>
                )
            })
        }

        return (
            <Drawer anchor="left" open={this.props.open}
                    onClose={this.props.onClose}>
                <div className={classes.drawerOrganisator}>
                    <List
                        component="nav"
                        subheader={<ListSubheader component="div" className={classes.subheader}>
                            <img src={Logo} alt="Nike Premier Cup" className={classes.logo}/></ListSubheader>}
                        className={classes.navBarList}
                    >

                        {this.getLink("Live", "/")}
                        {this.getLink("Schedule", "/schedule/")}


                        <ListItem button key="Results"
                                  onClick={() => this.setState({resultsOpen: !this.state.resultsOpen})}>
                            <ListItemText primary="Results"/>
                            {this.state.resultsOpen ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={this.state.resultsOpen} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                {groupLinks}
                                {this.getLink("Final round", "/results/finals/", true)}
                            </List>
                        </Collapse>

                        {this.getLink("Teams", "/teams/")}
                        {this.getLink("Rules", "/rules/")}


                        <ListItem button key="About"
                                  onClick={() => this.setState({aboutOpen: !this.state.aboutOpen})}>
                            <ListItemText primary="About"/>
                            {this.state.aboutOpen ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={this.state.aboutOpen} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                {this.getLink("Information", "/about/information/", true)}
                                {this.getLink("Schedule/Planner", "/about/planner/", true)}
                                {this.getLink("History", "/about/history/", true)}
                                {this.getLink("Hertha BSC", "/about/hertha/", true)}
                                {this.getLink("Work with young talents", "/about/work/", true)}
                            </List>
                        </Collapse>
                    </List>
                    <div className={classes.bottomNav}>
                        {this.getLinkWithoutIndicator("Terms of use", "/terms/", classes.item)}
                        {this.getLinkWithoutIndicator("Privacy", "/privacy/", classes.item)}
                    </div>
                </div>
            </Drawer>
        );
    }
}

export default withStyles(styles)(NavBarDrawer);