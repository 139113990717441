import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        display: "block",
        overflow: "hidden"
    },
    video: {
        display: "block",
        minWidth: "100%",
        minHeight: "100%",
    }
});

class ResponsiveVideo extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.src !== nextProps.src) {
            return true;
        }
        return false;
    }


    componentDidMount() {
        const video = this.videoContainer;
        video.autoplay = true;
        video.loop = true;
        video.muted = true; // fixes autoplay in chrome
        video.setAttribute('playsinline', 'true'); // fixes autoplay in webkit (ie. mobile safari)
        video.setAttribute('data-autoplay', 'true'); // fixes autoplay in webkit (ie. mobile safari)

        video.play();
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <video className={classes.video} autoPlay={true} loop={true} ref={(ref) => {
                    this.videoContainer = ref
                }}>
                    <source type="video/mp4" src={this.props.src}/>
                </video>
            </div>
        );
    }
}

export default withStyles(styles)(ResponsiveVideo);