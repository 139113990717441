import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import ResponsiveVideo from "../ResponsiveVideo/index";
import Typography from "@material-ui/core/Typography";
import TimerText from "../TimerText";
import Icon from '@material-ui/core/Icon';

const styles = (theme: any): any => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        cursor: "pointer"
    },
    overlay: {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100%",
        height: "100%",
        opacity: 0.5,
        background: theme.palette.primary.main,
        zIndex: 10
    },
    text: {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        width: "100%",
        height: "100%",
        zIndex: 20,
    },
    scoreWrapper: {
        height: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingBottom: "0.3rem"
    },
    score: {
        ...theme.typography.h1,
        fontSize: "4rem",
        width: "100%",
        height: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        textShadow: "1px 1px 3px " + theme.palette.secondary.contrastText
    },
    teamWrapper: {
        display: "flex",
        flexDirection: "row",
        height: "25%",
        overflow: "hidden",
        justifyContent: "space-between"
    },
    team: {
        ...theme.typography.h1,
        fontSize: "1.2rem",
        width: "45%",
        textShadow: "1px 1px 3px " + theme.palette.secondary.contrastText
    },
    timeWrapper: {
        height: "25%",
    },
    time: {
        ...theme.typography.h1,
        fontSize: "2rem",
        width: "100%",
        textShadow: "1px 1px 3px " + theme.palette.secondary.contrastText
    },
    timer: {
        lineHeight: "1.4",
        overflow: "visible",
        fontSize: "1.6rem",
        textShadow: "1px 1px 3px " + theme.palette.secondary.contrastText
    }
});

interface IVideoTileProps {
    classes: any;

    score: string | null;
    teamA: string | null;
    teamB: string | null;
    time: number;
    url: string | null;
}

interface IVideoTileStates {
    width: number;
    height: number;
    gridHeight: number;
}

class VideoTile extends Component<IVideoTileProps, IVideoTileStates> {
    state: IVideoTileStates = {
        width: 0,
        height: 0,
        gridHeight: 0
    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.overlay}>
                </div>
                <div className={classes.text}>
                    <div className={classes.scoreWrapper}>
                        <Typography className={classes.score}>
                            {this.props.score}
                        </Typography>
                    </div>
                    <div className={classes.teamWrapper}>
                        <Typography align="right" className={classes.team}>
                            {this.props.teamA}
                        </Typography>
                        <Typography align="left" className={classes.team}>
                            {this.props.teamB}
                        </Typography>
                    </div>
                    <div className={classes.timeWrapper}>
                        <Typography align="center" className={classes.time}>
                            <Icon className={classes.timer}>timer</Icon><TimerText
                            milliseconds={this.props.time}/>
                        </Typography>
                    </div>
                </div>
                <ResponsiveVideo src={this.props.url}/>
            </div>
        );
    }
}

export default withStyles(styles)(VideoTile);