import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import Background from "./background.jpg";
import Typography from "@material-ui/core/Typography";
import Logo from './logo.svg';

const styles = (theme: any): any => ({
    root: {
        width: "100%",
        height: "100%",
        backgroundImage: `url(${Background})`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        textTransform: "uppercase"
    },
    timeWrapper: {
        height: "70%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingBottom: "0.3rem",
        zIndex: 2,
        textShadow: "1px 1px 3px " + theme.palette.secondary.contrastText
    },
    time: {
        ...theme.typography.h1,
        fontSize: "4rem",
        width: "100%",
        textAlign: "center"
    },
    teamWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "25%",
        overflow: "hidden",
        justifyContent: "space-between",
        zIndex: 2
    },
    team: {
        ...theme.typography.h1,
        fontSize: "1.2rem",
        width: "45%",
        textShadow: "1px 1px 3px " + theme.palette.secondary.contrastText
    },
    backgroundLogo: {
        position: "absolute",
        top: "25%",
        left: "25%",
        height: "50%",
        width: "50%",
        zIndex: 1,
        opacity: 0.3
    }
});

const weekday: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

interface IPreviewTileProps {
    time: number;
    teamA: string;
    teamB: string;
    classes: any;
}

class PreviewTile extends Component<IPreviewTileProps, any> {
    render() {
        const {classes} = this.props;

        const date = new Date(this.props.time);
        const dateStr = weekday[date.getDay() + 1] + ", " + (date.getDate() + 1) + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

        const timeStr = date.getHours() + ":" + date.getMinutes() + "h";

        return (
            <div className={classes.root}>
                <img src={Logo} alt="Upcoming match" className={classes.backgroundLogo}/>
                <div className={classes.timeWrapper}>
                    <Typography>
                        {dateStr}
                    </Typography>
                    <Typography className={classes.time}>
                        {timeStr}
                    </Typography>
                </div>


                <div className={classes.teamWrapper}>
                    <Typography align="right" className={classes.team}>
                        {this.props.teamA}
                    </Typography>

                    <Typography align="left" className={classes.team}>
                        {this.props.teamB}
                    </Typography>
                </div>

            </div>
        );
    }
}

export default withStyles(styles)(PreviewTile);