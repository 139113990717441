import React, {Component} from 'react';
import ApiHelper, {IMatch} from "../../../api/ApiHelper";
import GridListTile from "@material-ui/core/GridListTile";
import GridList from "@material-ui/core/GridList";
import PreviewTile from "../../elements/PreviewTile";


interface IUpcomingMatchesListProps {
    className: string | undefined;
    apiHelper: ApiHelper;

    onVideoClicked(videoId: string): void;

    intervalInSeconds: number;
}

interface IIUpcomingMatchesListStates {
    matches: IMatch[],
    height: number
}

class UpcomingMatchesList extends Component<IUpcomingMatchesListProps, IIUpcomingMatchesListStates> {
    interval: NodeJS.Timeout | null = null;

    static defaultProps = {
        className: undefined
    };

    state: IIUpcomingMatchesListStates = {
        matches: [],
        height: 0
    };

    constructor(props: IUpcomingMatchesListProps) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    refresh = () => {
        this.props.apiHelper.getNextTwoMatches().then(matches => {
            if (matches)
                this.setState({
                    matches: matches
                });
        });
    };

    updateWindowDimensions() {
        const height = window.innerWidth < 800 ? ((window.innerWidth / 2) - 8) : (window.innerHeight / 2);
        this.setState({height: height});
    }

    componentDidUpdate(prevProps: Readonly<IUpcomingMatchesListProps>, prevState: Readonly<IIUpcomingMatchesListStates>, snapshot?: any): void {
        if (prevProps.intervalInSeconds !== this.props.intervalInSeconds) {
            if (this.interval)
                clearInterval(this.interval);
            this.applyInterval();
        }
    }

    applyInterval = () => {
        if (this.props.intervalInSeconds > 0)
            this.interval = setInterval(
                () => {
                    this.refresh();
                }, (this.props.intervalInSeconds * 1000));
    };

    componentDidMount() {
        this.refresh();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.applyInterval();
    }

    componentWillUnmount(): void {
        if (this.interval)
            clearInterval(this.interval);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        const numberOfUpcomingMatches = this.state.matches.length;

        return (
            <GridList className={this.props.className} cellHeight={this.state.height}
                      cols={2} spacing={8}>
                {this.state.matches.map((match: IMatch) => {
                    const teamACaption = match.attendeeA.team ? (match.attendeeA.team.abbreviation ? match.attendeeA.team.abbreviation : match.attendeeA.team.name) : match.attendeeA.platinLabel;
                    const teamBCaption = match.attendeeB.team ? (match.attendeeB.team.abbreviation ? match.attendeeB.team.abbreviation : match.attendeeB.team.name) : match.attendeeB.platinLabel;

                    return (
                        <GridListTile key={match._id} cols={(numberOfUpcomingMatches % 2) + 1}>
                            <PreviewTile time={match.timestamp}
                                         teamA={teamACaption}
                                         teamB={teamBCaption}/>
                        </GridListTile>
                    );
                })}
            </GridList>
        );
    }
}

export default UpcomingMatchesList;