import React, {Component} from 'react';

interface TimerTextProps {
    milliseconds: number;
}

interface TimerTextStats {
    milliseconds: number;
}

class TimerText extends Component<TimerTextProps, TimerTextStats> {
    interval: NodeJS.Timeout | null = null;

    constructor(props: TimerTextProps) {
        super(props);
        this.state = {
            milliseconds: props.milliseconds
        };
    }

    pad(number: number): string {
        var s = String(number);
        if (s.length == 1)
            s = "0" + s;
        return s;
    }


    shouldComponentUpdate(nextProps: Readonly<TimerTextProps>, nextState: Readonly<TimerTextStats>, nextContext: any): boolean {
        if (nextProps.milliseconds !== this.props.milliseconds)
            return true;
        if (nextState.milliseconds !== this.state.milliseconds)
            return true;
        return false;
    }

    componentWillUnmount(): void {
        if (this.interval)
            clearInterval(this.interval);
    }

    componentDidMount(): void {
        // let timer run
        this.interval = setInterval(() => {
            this.setState({
                milliseconds: (this.state.milliseconds + 1000)
            });
        }, 1000);

    }

    render() {
        const minutes: number = Math.round((this.state.milliseconds / 1000) / 60);
        const seconds: number = Math.round((this.state.milliseconds / 1000) % 60);
        return this.pad(minutes) + " " + this.pad(seconds);
    }
}

export default TimerText;