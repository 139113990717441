export interface IImage {
    path: string
}

export interface IGroup {
    _id: string;
    name: string;
    tournament: {
        _id: string;
        display: string;
        link: string;
    }
}

export interface IAttendee {
    _id: string;
    team: ITeam | null;
    group: IGroup;
    platinLabel: string;
}

export interface ITeam {
    _id: string;
    abbreviation: string | null;
    name: string;
    title: string;
    image: IImage | null;
    text: string | null;
    icon: IImage | null;
    imageDescription: string | null;
}

export interface IText {
    title: string | null,
    subtitle: string | null,
    icon: IImage | null;
    images: IImage[] | null;
    text: string | null;
}

export interface IMatch {
    _id: string;
    timestamp: number;
    attendeeA: IAttendee;
    attendeeB: IAttendee;
    scoreA: string;
    scoreB: string;
    penaltyKickA: string;
    penaltyKickB: string;
    pitch: string | null;
}

export interface IVideo {
    _id: string;
    match: IMatch;
    description: string | null;
    part: string | null;
    time: string | null;
    player: string | null;
    src: string | null;
}

export interface IGroupTable {
    _id: string;
    attendee: IAttendee;
    position: number;
    won: number;
    lost: number;
    drawn: number;
    points: number;
    goals: number;
    goalsAgainst: number;
    goalDifference: number;
}

export interface IStartPage {
    active: boolean;
    title: string;
    text: string;
    backgroundImage: IImage;
}

export default class ApiHelper {
    private static TIMEZONE_OFFSET = 2;
    private _url: string;
    private _apiKey: string;
    private _assetsUrl: string;
    private _isDemoModeEnabled: boolean = false;
    public static DEMO_TIME: Date = new Date(2019, 3, 9, 10, 5);
    public static LIVE_STREAM_IDENTIFIER = "Livestream";

    private groups: IGroup[] = [];
    private teams: ITeam[] = [];
    private matches: IMatch[] = [];

    get isDemoModeEnabled(): boolean {
        return this._isDemoModeEnabled;
    }

    set isDemoModeEnabled(value: boolean) {
        if (value) {
            setInterval(() => {
                ApiHelper.DEMO_TIME = new Date(ApiHelper.DEMO_TIME.getTime() + 1000);
            }, 1000);
        }
        this._isDemoModeEnabled = value;
    }

    get assetsUrl(): string {
        return this._assetsUrl;
    }

    set assetsUrl(value: string) {
        this._assetsUrl = value;
    }

    get apiKey(): string {
        return this._apiKey;
    }

    set apiKey(value: string) {
        this._apiKey = value;
    }

    get url(): string {
        return this._url;
    }

    set url(value: string) {
        this._url = value;
    }

    private parseJson = async (response: Response): Promise<any> => {
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error("Error on requesting '" + response.url + "': " + response.statusText);
        }
    };

    public getCurrentTimestamp = (): number => {
        if (this.isDemoModeEnabled) {
            //console.log("Warning DEMO MODE with fixed date at " + ApiHelper.DEMO_TIME.toString());
            return ApiHelper.DEMO_TIME.getTime();
        }
        const d = new Date();
        const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
        const germanDate = new Date(utc + (3600000 * ApiHelper.TIMEZONE_OFFSET));
        return germanDate.getTime();
    };

    getStartpage = async (): Promise<IStartPage | null> => {
        const url = this._url + "/api/singletons/get/startpage";
        const response = await fetch(url, {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this._apiKey
                })
            }
        );
        return this.parseJson(response);
    };

    private fetchGroups = async (): Promise<IGroup[] | null> => {
        const response = await fetch(this._url + "/api/collections/get/groups", {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                token: this._apiKey,
                sort: {sort: 1}
            })
        });
        const result = await this.parseJson(response);
        return result.entries;
    };

    private async fetchMatches(): Promise<IMatch[] | null> {
        const response = await fetch(this._url + "/api/collections/get/matches", {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this._apiKey,
                    sort: {
                        timestamp: 1
                    },
                    populate: 1
                })
            }
        );
        const result = await this.parseJson(response);
        // But remove when same team is playing against itself (future but invalid games now)
        const entries: IMatch[] = [];
        for (const entry of result.entries) {
            if (entry.attendeeA._id != entry.attendeeB._id) {
                entries.push(entry);
            } else {
                entry.attendeeA = {
                    team: null,
                    platinLabel: "TBA"
                };
                entry.attendeeB = {
                    team: null,
                    platinLabel: "TBA"
                };
                entries.push(entry);
            }
        }
        return entries;
    };

    private fetchTeams = async (): Promise<ITeam[] | null> => {
        const response = await fetch(this._url + "/api/collections/get/teams", {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                token: this._apiKey,
                sort: {name: 1}
            })
        });
        const result = await this.parseJson(response);
        return result.entries;
    };

    constructor(url: string, apiKey: string, assetsUrl: string) {
        if (url.endsWith("/") || assetsUrl.endsWith("/")) {
            throw new Error("The url should not end with / - please remove this");
        }
        this._url = url;
        this._apiKey = apiKey;
        this._assetsUrl = assetsUrl;
    }

    getAssetsUrl(): string {
        return this._assetsUrl;
    }

    getText = async (id: string): Promise<IText | null> => {
        const url = this._url + "/api/singletons/get/" + id;
        const response = await fetch(url, {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this._apiKey
                })
            }
        );
        return this.parseJson(response);
    };

    getVideo = async (id: string): Promise<IVideo | null> => {
        const response = await fetch(this._url + "/api/collections/get/videos", {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this._apiKey,
                    filter: {
                        "_id": id
                    },
                    limit: 1,
                    populate: 1
                })
            }
        );
        const result = await this.parseJson(response);
        if (result.entries && result.entries.length > 0)
            return result.entries[0];
        return null;
    };

    getVideosOfMatch = async (matchId: string): Promise<IVideo[]> => {
        const response = await fetch(this._url + "/api/collections/get/videos", {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this._apiKey,
                    filter: {
                        "match._id": matchId
                    },
                    sort: {
                        time: 1
                    },
                    populate: 1
                })
            }
        );
        const result = await this.parseJson(response);
        if (result.entries) {
            return result.entries;
        }
        return [];
    };

    getLiveStreamOfMatch = async (matchId: string): Promise<IVideo | null> => {
        const response = await fetch(this._url + "/api/collections/get/videos", {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this._apiKey,
                    filter: {
                        "match._id": matchId,
                        "description": ApiHelper.LIVE_STREAM_IDENTIFIER
                    },
                    limit: 1,
                    populate: 1
                })
            }
        );
        const result = await this.parseJson(response);
        if (result.entries) {
            return result.entries[0];
        }
        return null;
    };

    getLiveVideos = async (): Promise<IVideo[]> => {
        // Get live tournaments
        const liveTournaments = await this.getLiveTournaments();
        if (liveTournaments) {
            const videos: IVideo[] = [];
            for (const liveTournament of liveTournaments) {
                const liveStream = await this.getLiveStreamOfMatch(liveTournament._id);
                if (liveStream) {
                    videos.push(liveStream);
                }
            }
            return videos;
        }
        return [];
    };

    getLiveTournaments = async (): Promise<IMatch[] | null> => {
        const currentTimestamp = this.getCurrentTimestamp();
        const minTimestamp = currentTimestamp - (60000 * 30);

        const response = await fetch(this._url + "/api/collections/get/matches", {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this._apiKey,
                    filter: {
                        $and: [
                            {timestamp: {$gt: minTimestamp}},
                            {timestamp: {$lt: currentTimestamp}}
                        ],
                    },
                    sort: {
                        timestamp: -1
                    },
                    populate: 1
                })
            }
        );
        const result = await this.parseJson(response);
        return result.entries;
    };

    getNextTwoMatches = async (): Promise<IMatch[] | null> => {
        const currentTimestamp = this.getCurrentTimestamp();

        const response = await fetch(this._url + "/api/collections/get/matches", {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this._apiKey,
                    filter: {
                        $and: [
                            {timestamp: {$gt: currentTimestamp}}
                        ],
                    },
                    limit: 2,
                    sort: {
                        timestamp: 1
                    },
                    populate: 1
                })
            }
        );
        const result = await this.parseJson(response);
        return result.entries;
    };

    getGroups = async (): Promise<IGroup[]> => {
        if (this.groups.length == 0) {
            const groups: IGroup[] | null = await this.fetchGroups();
            if (groups) {
                this.groups = groups;
            }
        }
        return this.groups;
    };

    getMatches = async (): Promise<IMatch[]> => {
        if (this.matches.length == 0) {
            const matches: IMatch[] | null = await this.fetchMatches();
            if (matches) {
                this.matches = matches;
            }
        }
        return this.matches;
    };

    getNextMatches = async (): Promise<IMatch[]> => {
        const matches: IMatch[] = await this.getMatches();
        const currentTimeStamp = this.getCurrentTimestamp();
        const nextMatches: IMatch[] = [];
        for (const match of matches) {
            if (currentTimeStamp < match.timestamp) {
                nextMatches.push(match);
            }
        }
        return nextMatches;
    };

    getPastMatches = async (): Promise<IMatch[]> => {
        const matches: IMatch[] = await this.getMatches();
        const currentTimeStamp = this.getCurrentTimestamp();
        const pastMatches: IMatch[] = [];
        const matchDuration: number = (1800000);
        for (const match of matches) {
            if (match.timestamp <= currentTimeStamp) {
                pastMatches.push(match);
            }
        }
        console.log(pastMatches);
        return pastMatches;
    };

    getTeams = async (): Promise<ITeam[]> => {
        if (this.teams.length == 0) {
            const teams: ITeam[] | null = await this.fetchTeams();
            if (teams) {
                this.teams = teams;
            }
        }
        return this.teams;
    };

    getTeam = async (id: string): Promise<ITeam | null> => {
        const teams: ITeam[] = await this.getTeams();
        for (const team of teams) {
            if (team._id === id)
                return team;
        }
        return null;
    };

    getMatchesByTournamentId = async (tournamentId: string): Promise<IMatch[] | null> => {
        const response = await fetch(this._url + "/api/collections/get/matches", {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this._apiKey,
                    filter: {
                        "tournament._id": tournamentId
                    },
                    sort: {
                        timestamp: 1
                    },
                    populate: 1
                })
            }
        );
        const result = await this.parseJson(response);
        return result.entries;
    };

    getGroupsTableByGroupId = async (groupId: string): Promise<IGroupTable[] | null> => {
        const response = await fetch(this._url + "/api/collections/get/grouptable", {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this._apiKey,
                    filter: {"group._id": groupId},
                    sort: {
                        points: -1,
                        goalDifference: -1,
                        goal: -1,
                        weight: -1
                    },
                    populate: 1
                })
            }
        );
        const result = await this.parseJson(response);
        console.log(result);
        if (result.entries) {
            for (let i = 0; i < result.entries.length; i++) {
                result.entries[i].position = (i + 1);
            }
        }
        return result.entries;
    }
}