import {createMuiTheme} from '@material-ui/core/styles';
import 'typeface-roboto-condensed';
import 'typeface-poppins';
import 'typeface-nunito';

const textColor = '#000';
const contrastTextColor = '#fff';
const primaryColor = '#e1393d';
const secondaryColor = 'rgba(225, 57, 61, 1)';
const primaryBackgroundColor = '#212121';
const secondaryBackgroundColor = '#212121';

const typography = {
    fontFamily: "'Roboto Condensed', sans-serif",
};
const headlineTypography = {
    /* Poppins, Roboto Condensed, futura-pt-condensed */
    fontFamily: "futura-pt-condensed, 'Roboto Condensed', sans-serif",
    fontStyle: "italic",
    fontDisplay: "swap",
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: 0.25,
};

const themeName = 'Cinnabar Supernova Turkeys';

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            light: "rgba(255, 248, 79, 1)",
            main: primaryColor,
            dark: "rgba(199, 149, 0, 1)",
            contrastText: contrastTextColor,
            background: {
                paper: {
                    background: secondaryBackgroundColor,
                }
            }
        },
        secondary: {
            light: "rgba(255, 111, 104, 1)",
            main: secondaryColor,
            dark: "rgba(168, 0, 22, 1)",
            contrastText: textColor,
            background: {
                paper: {
                    background: secondaryBackgroundColor,
                }
            }
        },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: contrastTextColor
        },
        background: {
            paper: secondaryBackgroundColor,
            default: secondaryBackgroundColor
        },
        text: {
            primary: "rgba(255, 255, 255, 0.87)",
            secondary: "rgba(180, 180, 180, 0.54)",
            disabled: "rgba(223, 223, 223, 0.38)",
            hint: "rgba(179, 179, 179, 0.38)"
        }
    },
    typography: {
        useNextVariants: true,
        ...typography,
        h1: {
            ...headlineTypography,
            fontSize: "3rem"
        },
        h2: headlineTypography,
        h3: headlineTypography,
        h4: headlineTypography,
        h5: headlineTypography,
        h6: {
            ...headlineTypography,
            fontSize: "1.4rem"
        },
        body1: {},
        body2: {
            color: "rgba(255, 255, 255, 0.6)",
        },
        breadcrumb: {
            ...headlineTypography,
            color: primaryColor
        },
        brand: {
            ...headlineTypography,
            color: contrastTextColor
        },
        drawerHeadline: {
            ...headlineTypography,
            fontSize: "1.4rem",
            color: contrastTextColor,
            lineHeight: 1
        }
    }
}, themeName);

const {breakpoints} = theme;

const responsiveTheme = {
    ...theme,
    "@global": {
        html: {
            "-webkit-font-smoothing": "antialiased",
            "-moz-osx-font-smoothing": "grayscale",
            [breakpoints.up("xs")]: {
                fontSize: "100%"
            },
            [breakpoints.up("sm")]: {
                fontSize: "150%"
            },
            [breakpoints.up("md")]: {
                fontSize: "200%"
            },
            [breakpoints.up("lg")]: {
                fontSize: "250%"
            }
        },
        body: {
            backgroundColor: secondaryBackgroundColor,
            margin: 0,
            padding: 0
        },
        ".App": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        },
    },
    overrides: {
        MuiGrid: {
            container: {
                maxWidth: 1200,
                justifyContent: 'center'
            }
        },
        MuiDrawer: {
            paper: {
                backgroundColor: primaryBackgroundColor
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: primaryBackgroundColor,
            }
        },
        MuiCardHeader: {
            root: {
                color: primaryColor
            }
        },
        MuiCard: {
            root: {
                backgroundColor: primaryBackgroundColor,
            }
        },
        MuiListSubheader: {
            root: {
                ...theme.typography.h6,
                color: theme.palette.secondary.main
            }
        },
        MuiListItem: {
            root: {}
        },
        MuiTableCell: {
            root: {
                padding: "0"
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: "1rem"
            }
        }
    }
};
export default responsiveTheme;