import React, {Component} from 'react';
import TextContent from './views/TextContent';
import './App.css';
import Theme from './Theme.js';
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    RouteComponentProps, withRouter
} from 'react-router-dom';
import NavBar from './components/elements/NavBar';
import NavBarDrawer from './components/elements/NavBarDrawer';
import Live from './views/Live';
import Results from './views/FinalRoundResults';
import GroupResults from './views/GroupResults';
import Teams from './views/Teams';
import LiveStream from './views/LiveStream';
import MatchView from './views/MatchView';
import ScheduleView from './views/ScheduleView';
import Logo from './logo.svg';
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid} from "@material-ui/core";
import ApiHelper from "./api/ApiHelper";

const supportsHistory = 'pushState' in window.history;

const styles = (theme: any): any => ({
        ...Theme,
        base: {
            overflow: "hidden"
        },
        root: {
            width: '100%',
            maxWidth:
                360,
            backgroundColor:
            theme.palette.background.paper,
        }
        ,
        navBarList: {
            width: 250,
        }
        ,
        drawer: {
            width: 250
        }
        ,
        drawerOrganisator: {
            height: "100%",
            display:
                "flex",
            flexDirection:
                "column",
            alignItems:
                "space-between",
            justifyContent:
                "space-between"
        }
        ,
        nested: {
            paddingLeft: theme.spacing.unit * 4,
        }
        ,
        grow: {
            flexGrow: 1
        }
        ,
        bottomNav: {
            display: "flex",
            flexDirection:
                "row",
            alignItems:
                "space-around",
            justifyContent:
                "space-around"
        }
    })
;

interface IAppProps {
    apiHelper: ApiHelper;
    assetsUrl: string;
    location: any;
    classes: any;
}

interface IAppStates {
    title: string;
    isMenuOpen: boolean;
    apiHelper: ApiHelper;
}


class App extends Component<IAppProps, IAppStates> {
    title: string = "";

    constructor(props: any) {
        super(props);
        this.state = {
            isMenuOpen: false,
            title: '',
            apiHelper: this.props.apiHelper
        };
    }

    shouldComponentUpdate(nextProps: IAppProps, nextState: IAppStates) {
        if (this.state.isMenuOpen !== nextState.isMenuOpen) {
            return true;
        } else if (this.state.title !== nextState.title) {
            return true;
        }
        return false;
    }

    openMenu = (): void => {
        this.setState({
            isMenuOpen: true
        });
    };

    closeMenu = (): void => {
        this.setState({
            isMenuOpen: false
        });
    };

    withContainer = (children: any) => {
        return (
            <Grid container style={{margin: '0 auto'}}>
                {children}
            </Grid>
        )
    };

    generateTextElement = (id: string) => {
        return this.withContainer(
            <TextContent
                apiHelper={this.state.apiHelper}
                id={id}/>
        );
    };

    render() {
        const {classes} = this.props;

        const ChangeTracker = withRouter(({match, location, history}): any => {
            if (this.state.title !== this.title)
                this.setState({
                    title: this.title
                });
            return false;
        });

        const live = (props: any) => {
            this.title = "Live";
            return (
                <Live apiHelper={this.state.apiHelper} onClick={(idx: string) => {
                    console.log(idx);
                    props.history.push("/match/" + idx);
                }} onLiveClicked={(idx: string) => {
                    props.history.push("/live/" + idx);
                }}/>
            );
        };

        const liveStream = (props: any) => {
            this.title = "Live";
            let matchId: string = props.match.params.matchId;
            let videoId: string = props.match.params.videoId;
            return (
                <LiveStream apiHelper={this.state.apiHelper} matchId={props.match.params.id} baseRoute="/live"
                            onMatchIdChanged={(id: string) => {
                                matchId = id;
                                props.history.replace("/live/" + matchId);
                            }}
                            onVideoIdChanged={(id: string) => {
                                videoId = id;
                                props.history.replace("/live/" + matchId + "/" + videoId);
                            }}/>
            );
        };

        const match = (props: any) => {
            this.title = "Stream";
            console.log("MatchID=" + props.match.params.matchId);
            console.log("VideoID=" + props.match.params.videoId);
            return (
                <MatchView apiHelper={this.state.apiHelper} baseRoute="/match" matchId={props.match.params.matchId}
                           currentVideoId={props.match.params.videoId} onVideoClicked={(videoId: string) => {
                    props.history.replace("/match/" + props.match.params.matchId + "/" + videoId);
                }}/>
            );
        };

        const schedule = () => {
            this.title = "Schedule";
            return this.withContainer(
                <ScheduleView apiHelper={this.state.apiHelper}/>
            );
        };

        const groupResults = (props: RouteComponentProps<any>) => {
            this.title = "Results";
            return this.withContainer(
                <GroupResults groupId={props.match.params.groupId}
                              apiHelper={this.state.apiHelper}
                              onGroupChangeRequested={(groupId: string) => {
                                  props.history.push("/results/groups/" + groupId);
                              }}/>
            );
        };

        const results = (props: RouteComponentProps<any>) => {
            this.title = "Results";
            return this.withContainer(
                <Results apiHelper={this.state.apiHelper} {...props.match.params}/>
            );
        };

        const teams = (props: RouteComponentProps<any>) => {
            this.title = "Teams";
            return this.withContainer(
                <Teams apiHelper={this.state.apiHelper} id={props.match.params.id} {...props.match.params}
                       onTeamURLChangeRequested={(teamUrl: string) => {
                           props.history.push(teamUrl);
                       }}/>
            );
        };

        const rules = () => {
            this.title = "Rules";
            return this.generateTextElement("rules");
        };

        // about/
        const information = () => {
            this.title = "Information";
            return this.generateTextElement("information");
        };

        const planner = () => {
            this.title = "Planner";
            return this.generateTextElement("planner");
        };

        const history = () => {
            this.title = "History";
            return this.generateTextElement("history");
        };
        const hertha = () => {
            this.title = "Hertha BSC";
            return this.generateTextElement("hertha");
        };
        const work = () => {
            this.title = "Our work";
            return this.generateTextElement("work");
        };

        return (
            <Router forceRefresh={!supportsHistory}>
                <MuiThemeProvider theme={Theme}>
                    <NavBar onOpenClicked={this.openMenu} logo={Logo}
                            brand="Nike Premier Cup" title={this.state.title}>
                        <NavBarDrawer apiHelper={this.state.apiHelper}
                                      onLinkClicked={this.closeMenu}
                                      open={this.state.isMenuOpen}
                                      onClose={this.closeMenu}/>
                    </NavBar>
                    <div className={classes.base}>
                        <Switch>
                            <Route exact path="/demo" render={(props: RouteComponentProps<any>) => {
                                this.props.apiHelper.url = "https://www.root-design.de/2018";
                                this.props.apiHelper.apiKey = "9fd08dc415f0baa82ebcf33c86c6cd";
                                this.props.apiHelper.assetsUrl = "https://www.root-design.de/2018";
                                ApiHelper.DEMO_TIME = new Date(2018, 3, 1, 9, 47, 50);
                                ApiHelper.LIVE_STREAM_IDENTIFIER = "Zusammenfassung";
                                this.props.apiHelper.isDemoModeEnabled = true;
                                return live(props);
                            }}></Route>
                            <Route name="Home" exact path="/">
                                <Redirect to="/live"/>
                            </Route>
                            <Route exact path="/live" render={live}>
                            </Route>
                            <Route path="/live/:matchId?/:videoId?"
                                   render={(props: RouteComponentProps<any>) => liveStream(props)}>
                            </Route>
                            <Route path="/match/:matchId?/:videoId?"
                                   render={(props: RouteComponentProps<any>) => match(props)}>
                            </Route>
                            <Route path="/schedule/" render={schedule}>
                            </Route>
                            <Route path="/results/groups/:groupId"
                                   render={(props: RouteComponentProps<any>) => groupResults(props)}>
                            </Route>
                            <Route path="/results/finals/:id"
                                   render={(props: RouteComponentProps<any>) => results(props)}>
                            </Route>
                            <Route exact path="/teams">
                                <Redirect to="/teams/0"/>
                            </Route>
                            <Route path="/teams/:id"
                                   render={(props: RouteComponentProps<any>) => teams(props)}>
                            </Route>
                            <Route path="/rules/" render={rules}>
                            </Route>
                            <Route path="/about/information/" render={information}>
                            </Route>
                            <Route path="/about/planner/" render={planner}>
                            </Route>
                            <Route path="/about/history/" render={history}>
                            </Route>
                            <Route path="/about/hertha/" render={hertha}>
                            </Route>
                            <Route path="/about/work/" render={work}>
                            </Route>
                        </Switch>
                    </div>
                </MuiThemeProvider>
                <ChangeTracker/>
            </Router>
        );
    }
}

export default withStyles(styles)(App);