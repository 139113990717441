import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import ResultTable from "../../components/containers/ResultTable";
import NavigationSelector from "../../components/elements/NavigationSelector";
import PropTypes from "prop-types";

const styles = theme => ({
    root: {
        maxWidth: '100%',
        overflowY: 'scroll'
    }
});


class Results extends Component {
    state = {
        tournaments: null
    };

    fetchTournaments = () => {
        fetch(this.props.tournamentUrl, {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this.props.apiKey,
                    sort: {sort: 1}
                })
            }
        ).then(res => res.json()).then(res => {
                if (res.entries) {
                    this.setState({
                        tournaments: res.entries
                    });
                }
            }
        );
    };

    fetchGroupsTable = () => {
        fetch(this.props.groupTableUrl, {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: this.props.apiKey,
                    sort: {name: 1}
                })
            }
        ).then(res => res.json()).then(res => {
                if (res.entries) {
                    this.setState({
                        teams: res.entries
                    });
                    if (this.props.id && this.props.id < res.entries.length) {
                        const data = res.entries[this.props.id];
                        const currentTeam = {
                            abbreviation: data.abbreviation,
                            title: data.name,
                            text: data.description,
                            icon: data.icon ? this.props.assetsUrl + "/" + data.icon.path : null,
                            image: data.image ? this.props.assetsUrl + "/" + data.image.path : null,
                            imageDescription: data.imageDescription
                        };
                        this.setState({
                            currentTeam: currentTeam
                        });
                    }
                }
            }
        );
    };

    componentDidMount() {
        this.fetchTournaments();
    }


    render() {
        const {classes} = this.props;

        const selector = this.state.tournaments ? (
            <NavigationSelector data={this.state.tournaments.map((value, idx) => {
                return {
                    value: '/results/' + idx,
                    caption: value.caption,
                };
            })}/>
        ) : null;

        return (
            <div className={classes.root}>
                {selector}
                <ResultTable>
                </ResultTable>
            </div>
        );
    }
}

Results.propTypes = {
    tournamentUrl: PropTypes.string.isRequired,
    groupTableUrl: PropTypes.string.isRequired,
    matchesUrl: PropTypes.string.isRequired,
    assetsUrl: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired
};

export default withStyles(styles)(Results);