import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ApiHelper, {IMatch} from "../../../api/ApiHelper";
import Avatar from "@material-ui/core/Avatar";

const styles = (theme: any): any => ({
    root: {
        flexGrow: 1,
    },
    signalColorYellow: {
        color: theme.palette.primary.dark
    },
    signalColorRed: {
        color: theme.palette.secondary.dark
    },
    withWordBreak: {
        wordBreak: "break-word"
    },
    teamLeft: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "100%"
    },
    teamRight: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100%"
    },
    avatar: {
        display: "inline-flex"
    },
    columnLeft: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    columnRight: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    result: {
        display: 'inline-flex',
        flex: '0 0 auto',
        backgroundColor: '#333'
    },
    resultText: {
        ...theme.typography.h1,
        fontSize: "1.4rem",
        padding: 10
    }
});

interface IMatchListProps {
    classes: any;
    className: string | null;
    intervalInSeconds: number;
    apiHelper: ApiHelper;
    tournamentId: string | undefined;
}

interface IMatchListStates {
    matches: IMatch[];
    width: number;
    height: number;
    gridHeight: number;
}


class MatchList extends Component<IMatchListProps, IMatchListStates> {
    interval: NodeJS.Timeout | null = null;

    static defaultProps = {
        className: null,
        tournamentId: undefined
    };

    state: IMatchListStates = {
        width: 0,
        height: 0,
        gridHeight: 0,
        matches: []
    };

    refresh = () => {
        if (this.props.tournamentId) {
            this.props.apiHelper.getMatchesByTournamentId(this.props.tournamentId).then(
                (matches: IMatch[] | null) => {
                    if (matches)
                        this.setState({
                            matches: matches
                        });
                }
            )
        } else {
            this.props.apiHelper.getMatches().then(
                (matches) => {
                    this.setState({matches: matches})
                }
            );
        }
    };

    componentDidUpdate(prevProps: Readonly<IMatchListProps>, prevState: Readonly<IMatchListStates>, snapshot?: any): void {
        if (prevProps.intervalInSeconds !== this.props.intervalInSeconds) {
            if (this.interval)
                clearInterval(this.interval);
            this.applyInterval();
        }
        if (prevProps.tournamentId !== this.props.tournamentId) {
            this.refresh();
        }
    }

    applyInterval = () => {
        if (this.props.intervalInSeconds > 0)
            this.interval = setInterval(
                () => {
                    this.refresh();
                }, (this.props.intervalInSeconds * 1000));
    };

    componentDidMount() {
        this.refresh();
        this.applyInterval();
    }

    componentWillUnmount(): void {
        if (this.interval)
            clearInterval(this.interval);
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={this.props.className ? this.props.className : classes.root}>
                {this.state.matches.map((match: IMatch, idx) => {
                    const now = this.props.apiHelper.getCurrentTimestamp();

                    const date = new Date();
                    date.setTime(match.timestamp);

                    const day: string = (date.getDate() + 1) + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                    let minutes: string = date.getMinutes().toString();
                    if (minutes.length === 1)
                        minutes = "0" + minutes;
                    const time: string = date.getHours() + ":" + minutes + "h";

                    const teamACaption = match.attendeeA.team ? (match.attendeeA.team.abbreviation ? match.attendeeA.team.abbreviation : match.attendeeA.team.name) : match.attendeeA.platinLabel;
                    const teamBCaption = match.attendeeB.team ? (match.attendeeB.team.abbreviation ? match.attendeeB.team.abbreviation : match.attendeeB.team.name) : match.attendeeB.platinLabel;

                    if (match.timestamp > 0 && match.timestamp > now) {
                        return (
                            <Grid key={idx} container spacing={24}>
                                <Grid item xs={4}>
                                    <Typography variant="body1" align="right" className={classes.teamLeft}>
                                        {teamACaption}
                                        {match.attendeeA.team && match.attendeeA.team.icon ?
                                            <Avatar className={classes.avatar}
                                                    src={this.props.apiHelper.getAssetsUrl() + "/" + match.attendeeA.team.icon.path}/> : null}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" align="center" className={classes.signalColorYellow}>
                                        {day}</Typography>
                                    <Typography variant="body2" align="center"
                                                className={classes.signalColorYellow}>{time}
                                    </Typography>
                                    {match.pitch ? <Typography
                                        className={classes.signalColorRed}>{match.pitch}</Typography> : null}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1" align="left" className={classes.teamRight}>
                                        {match.attendeeB.team && match.attendeeB.team.icon ?
                                            <Avatar className={classes.avatar}
                                                    src={this.props.apiHelper.getAssetsUrl() + "/" + match.attendeeB.team.icon.path}/> : null}
                                        {teamBCaption}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    } else {
                        const scoreA = "";
                        const scoreB = "";

                        return (
                            <Grid key={idx} container spacing={24}>
                                <Grid item xs={6} className={classes.columnLeft}>
                                    <Typography variant="body1" align="right" className={classes.teamLeft}>
                                        {teamACaption}
                                    </Typography>
                                    {match.attendeeA.team && match.attendeeA.team.icon ?
                                        <Avatar className={classes.avatar}
                                                src={this.props.apiHelper.getAssetsUrl() + "/" + match.attendeeA.team.icon.path}/> : null}
                                    <div className={classes.result}>
                                        <Typography className={classes.resultText}>
                                            {match.penaltyKickA ? match.scoreA + " (" + match.penaltyKickA + ")" : match.scoreA}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} className={classes.columnRight}>
                                    <div className={classes.result}>
                                        <Typography className={classes.resultText}>
                                            {match.penaltyKickB ? match.scoreB + " (n.E. " + match.penaltyKickB + ")" : match.scoreB}
                                        </Typography>
                                    </div>
                                    {match.attendeeB.team && match.attendeeB.team.icon ?
                                        <Avatar className={classes.avatar}
                                                src={this.props.apiHelper.getAssetsUrl() + "/" + match.attendeeB.team.icon.path}/> : null}

                                    <Typography variant="body1" align="left" className={classes.teamRight}>
                                        {teamBCaption}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                })}
            </div>
        );
    }
}

export default withStyles(styles)(MatchList);