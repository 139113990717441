import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import ReactPlayer from "react-player";
import ApiHelper, {IVideo} from "../../api/ApiHelper";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";

const styles = (theme: any): any => ({
    [theme.breakpoints.up('sm')]: {
        player: {}
    },
    [theme.breakpoints.down('sm')]: {
        player: {},
    },
    videoWrapper: {
        display: "flex",
        width: "100%"
    },
    listWrapper: {
        display: "flex",
        width: "100%"
    },
    list: {
        width: "100%"
    },
    listItem: {
        width: "100%",
        cursor: "pointer"
    }
});

interface IMatchVideoPlayerProps {
    apiHelper: ApiHelper;
    baseRoute: string;
    matchId: string;
    currentVideoId: string | undefined;
    classes: any;
    className: string;

    onVideoClicked(videoId: string): void;
}

interface IMatchVideoPlayerStats {
    videos: IVideo[],
    currentVideo: IVideo | undefined
}

class MatchView extends Component<IMatchVideoPlayerProps, IMatchVideoPlayerStats> {
    static defaultProps = {
        currentVideoId: undefined,
        className: undefined
    };

    constructor(props: IMatchVideoPlayerProps) {
        super(props);
        this.state = {
            videos: [],
            currentVideo: undefined
        };
    }

    componentDidMount(): void {
        this.fetchVideos();
    }

    fetchVideos = () => {
        this.props.apiHelper.getVideosOfMatch(this.props.matchId).then((videos: IVideo[]) => {
            this.setState({
                videos: videos
            });
            this.setCurrentVideo(this.props.currentVideoId);
        });
    };

    shouldComponentUpdate(nextProps: Readonly<IMatchVideoPlayerProps>, nextState: Readonly<IMatchVideoPlayerStats>, nextContext: any): boolean {
        if (this.props.baseRoute !== nextProps.baseRoute)
            return true;
        if (this.props.matchId !== nextProps.matchId)
            return true;
        if (this.props.currentVideoId !== nextProps.currentVideoId)
            return true;
        if (this.state !== nextState)
            return true;
        return false;
    }

    setCurrentVideo = (id: string | undefined) => {
        if (id) {
            for (const video of this.state.videos) {
                if (video._id === id) {
                    this.setState({
                        currentVideo: video
                    });
                    break;
                }
            }
            this.props.onVideoClicked(id);
        } else if (this.state.videos.length > 0) {
            // Use video with description="Zusammenfassung" or just the first
            let foundVideo = null;
            for (const video of this.state.videos) {
                if (video.description == "Zusammenfassung") {
                    foundVideo = video;
                    break;
                }
            }
            if (!foundVideo)
                foundVideo = this.state.videos[0];
            this.setState({
                currentVideo: foundVideo
            });
            this.props.onVideoClicked(foundVideo._id);
        }
    };

    createIcon = (src: string, alt: string) => (
        <ListItemIcon>
            <Avatar>
                <img alt={alt} src={this.props.apiHelper.getAssetsUrl() + "/" + src}/>
            </Avatar>
        </ListItemIcon>
    );

    render() {
        const {classes} = this.props;

        const video = this.state.currentVideo && this.state.currentVideo.src ? (
            <ReactPlayer className={classes.player} url={this.state.currentVideo.src}
                         controls={this.state.currentVideo.description !== "Livestream"} playsinline
                         width="100%" height="100%"
                         playing config={{
                youtube: {
                    playerVars: {
                        controls: 1,
                        disablekb: 1,
                        fs: 0
                    }
                }
            }}/>
        ) : null;


        return (
            <div className={this.props.className}>
                <div className={classes.videoWrapper}>
                    {video}
                </div>
                <div className={classes.listWrapper}>
                    <List className={classes.list}>
                        {this.state.videos.map((video: IVideo) => {
                            return (
                                <ListItem key={video._id}
                                          selected={this.state.currentVideo ? this.state.currentVideo._id == video._id : false}
                                          className={classes.listItem}
                                          onClick={() => this.setCurrentVideo(video._id)}>
                                    {video.match.attendeeA.team && video.match.attendeeA.team.icon ? this.createIcon(video.match.attendeeA.team.icon.path, video.match.attendeeA.team.name) : null}
                                    {video.match.attendeeB.team && video.match.attendeeB.team.icon ? this.createIcon(video.match.attendeeB.team.icon.path, video.match.attendeeB.team.name) : null}
                                    {video.time}
                                    <ListItemText inset primary={video.description} secondary={video.part}/>
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(MatchView);