import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import {IImage} from "../../../api/ApiHelper";
import {GridList, GridListTile} from "@material-ui/core";

const styles = (): any => ({
    featured: {
        objectFit: 'cover',
        objectPosition: '0 0'
    },
    nonFeatures: {
        objectFit: 'cover'
    },
    optionalNonFeatures: {
        width: "100%",
        objectFit: "cover",
        height: "40vw",
        maxHeight: 200
    }
});

interface IImageGridProps {
    imageUrlPrefix: string | undefined;
    images: IImage[] | null;
    classes: any;
}

interface IImageGridState {
}

class ImageGrid extends Component<IImageGridProps, IImageGridState> {
    state: IImageGridState = {};

    shouldComponentUpdate(nextProps: Readonly<IImageGridProps>, nextState: Readonly<IImageGridState>, nextContext: any): boolean {
        return this.props.images !== nextProps.images || this.props.imageUrlPrefix !== nextProps.imageUrlPrefix;
    }

    render() {
        const {classes} = this.props;

        if (this.props.images) {
            if (this.props.images.length > 0) {
                if (this.props.images.length === 1) {
                    return (
                        <img src={this.props.imageUrlPrefix + "/" + this.props.images[0].path}
                             alt={this.props.images[0].path} style={{width: "100%"}}/>
                    )
                }
                return (
                    <div>
                        <GridList cellHeight='auto' cols={3}>
                            <GridListTile key={this.props.images[0].path} cols={3}>
                                <img src={this.props.imageUrlPrefix + "/" + this.props.images[0].path}
                                     alt={this.props.images[0].path}/>
                            </GridListTile>
                        </GridList>
                        {this.props.images.length > 1 ? (
                            <GridList cellHeight={200} cols={3}>
                                {this.props.images.map((image: IImage, idx: number) => {
                                    if (idx > 0) {
                                        return (
                                            <GridListTile key={image.path} cols={1}>
                                                <img src={this.props.imageUrlPrefix + "/" + image.path}
                                                     alt={image.path}/>
                                            </GridListTile>);
                                    }
                                    return null;
                                })}
                            </GridList>
                        ) : null}
                    </div>
                )
            }
            /*
                        return this.props.images.length > 1 ? (
                            <GridList cellHeight={200} cols={3} spacing={1}>
                                {this.props.images.map((image: IImage, idx: number) => {
                                    const isFeatured: boolean = idx === 0;
                                    return (
                                        <GridListTile key={image.path} cols={isFeatured ? 3 : 1} rows={isFeatured ? 2 : 1}>
                                            <img src={this.props.imageUrlPrefix + "/" + image.path}
                                                 alt={image.path}
                                                 className={isFeatured ? classes.featured : classes.nonFeatures}/>
                                        </GridListTile>
                                    );
                                })}
                            </GridList>
                        ) : null;*/
        }
        return null;
    }
}

export default withStyles(styles)(ImageGrid);