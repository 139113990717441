import React, {Component, RefObject} from 'react';
import ApiHelper, {IVideo} from "../../../api/ApiHelper";
import GridListTile from "@material-ui/core/GridListTile";
import VideoTile from "../../elements/VideoTile";
import GridList from "@material-ui/core/GridList";


interface ILiveListProps {
    className: string | undefined;
    apiHelper: ApiHelper;

    onVideoClicked(videoId: string): void;

    intervalInSeconds: number;
}

interface ILiveListStates {
    videos: IVideo[],
    height: number
}

class LiveList extends Component<ILiveListProps, ILiveListStates> {
    interval: NodeJS.Timeout | null = null;
    gridRef: any;

    static defaultProps = {
        className: undefined
    };

    state: ILiveListStates = {
        videos: [],
        height: 0
    };

    constructor(props: ILiveListProps) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.gridRef = React.createRef();
    }

    refresh = () => {
        this.props.apiHelper.getLiveVideos().then((videos: IVideo[]) => {
            this.setState({
                videos: videos
            });
        });
    };

    updateWindowDimensions() {
        const height = window.innerWidth < 800 ? ((window.innerWidth / 2) - 8) : (window.innerHeight / 2);
        this.setState({height: height});
    }

    componentDidUpdate(prevProps: Readonly<ILiveListProps>, prevState: Readonly<ILiveListStates>, snapshot?: any): void {
        if (prevProps.intervalInSeconds !== this.props.intervalInSeconds) {
            if (this.interval)
                clearInterval(this.interval);
            this.applyInterval();
        }
    }

    applyInterval = () => {
        if (this.props.intervalInSeconds > 0)
            this.interval = setInterval(
                () => {
                    this.refresh();
                }, (this.props.intervalInSeconds * 1000));
    };

    componentDidMount() {
        this.refresh();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.applyInterval();
    }

    componentWillUnmount(): void {
        if (this.interval)
            clearInterval(this.interval);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        const numberOfLiveStreams = this.state.videos.length;

        return (
            <GridList className={this.props.className} cellHeight={this.state.height} cols={2}
                      spacing={8}>
                {this.state.videos.map((liveStream: IVideo) => {
                    const runningTime = this.props.apiHelper.getCurrentTimestamp() - liveStream.match.timestamp;

                    const teamACaption = liveStream.match.attendeeA.team ? (liveStream.match.attendeeA.team.abbreviation ? liveStream.match.attendeeA.team.abbreviation : liveStream.match.attendeeA.team.name) : liveStream.match.attendeeA.platinLabel;
                    const teamBCaption = liveStream.match.attendeeB.team ? (liveStream.match.attendeeB.team.abbreviation ? liveStream.match.attendeeB.team.abbreviation : liveStream.match.attendeeB.team.name) : liveStream.match.attendeeB.platinLabel;

                    return (
                        <GridListTile key={liveStream._id} cols={(numberOfLiveStreams % 2) + 1} onClick={() => {
                            this.props.onVideoClicked(liveStream._id)
                        }}>
                            <VideoTile url={liveStream.src}
                                       score={liveStream.match.scoreA + ":" + liveStream.match.scoreB}
                                       time={runningTime}
                                       teamA={teamACaption}
                                       teamB={teamBCaption}/>
                        </GridListTile>
                    )
                })}
            </GridList>
        );
    }
}

export default LiveList;