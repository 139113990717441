import React, {Component} from 'react';
import {Typography, withStyles} from "@material-ui/core";
import PastMatchesList from "../../components/containers/PastMatchesList";
import ApiHelper, {IStartPage} from "../../api/ApiHelper";
import LiveList from "../../components/containers/LiveList";
import UpcomingMatchesList from "../../components/containers/UpcomingMatchesList";

const styles = (theme: any): any => ({
    root: {
        flexGrow: 1,
        width: "100%",
        height: "100%"
    },
    tile: {
        background: "red"
    },
    grow: {
        flexGrow: 1
    },
    startPage: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
    },
    backgroundImage: {
        zIndex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    featured: {
        position: "relative",
        padding: 50,
        zIndex: 100,
        textShadow: "1px 1px 3px " + theme.palette.secondary.contrastText
    }
});

interface ILiveProps {
    apiHelper: ApiHelper;

    classes: any;

    onClick(id: string): void;

    onLiveClicked(id: string): void;
}

interface ILiveStates {
    startPage: IStartPage | null
}


class Live extends Component<ILiveProps, ILiveStates> {
    state: ILiveStates = {
        startPage: null
    };

    componentDidMount(): void {
        this.fetchLandingPage();
    }

    fetchLandingPage = () => {
        this.props.apiHelper.getStartpage().then((startPage: IStartPage | null) => {
            this.setState({
                startPage: startPage
            });
        })
    };

    render() {
        const {classes} = this.props;

        if (this.state.startPage && this.state.startPage.active) {
            return (
                <div className={classes.root}>
                    <div className={classes.startPage}>
                        {this.state.startPage.backgroundImage ? <img
                            src={this.props.apiHelper.getAssetsUrl() + "/" + this.state.startPage.backgroundImage.path}
                            className={classes.backgroundImage}/> : null}
                        <div className={classes.featured}>
                            <Typography variant="h1">
                                {this.state.startPage.title}
                            </Typography>
                            <Typography variant="body1">
                                {this.state.startPage.text}
                            </Typography>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.root}>
                <LiveList className={classes.grow} apiHelper={this.props.apiHelper} intervalInSeconds={3}
                          onVideoClicked={this.props.onLiveClicked}/>
                <UpcomingMatchesList className={classes.grow} apiHelper={this.props.apiHelper} intervalInSeconds={6}
                                     onVideoClicked={this.props.onClick}/>
                <PastMatchesList apiHelper={this.props.apiHelper} onClick={this.props.onClick}/>
            </div>
        );
    }
}

export default withStyles(styles)(Live);